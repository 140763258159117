// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DaumPlaceInput-module-daumAutocompleteContainer-r6M9H{position:relative;z-index:3}.DaumPlaceInput-module-daumAutocompleteWidgetInput-fUdbn:read-only{cursor:pointer;border:1px solid #373a36;background-color:rgba(0,0,0,0)}.DaumPlaceInput-module-daumAutocompleteWidgetContainer-OYK0d{top:0;inset-inline-start:0;position:fixed;width:100%;height:100%;z-index:3;background:#fff}@media(min-width: 960px){.DaumPlaceInput-module-daumAutocompleteWidgetContainer-OYK0d{background:rgba(0,0,0,0);width:auto;height:auto;position:absolute;top:50px;inset-inline-start:0;box-shadow:0 2px 8px 0 rgba(0,0,0,.2)}}.DaumPlaceInput-module-mobileWidgetCloseIcon-ZneCP{padding-top:20px;text-align:end;padding-inline-end:20px;margin-bottom:10px}@media(min-width: 960px){.DaumPlaceInput-module-mobileWidgetCloseIcon-ZneCP{display:none}}.DaumPlaceInput-module-daumAutocompleteWidgetInactive-cRY9a{display:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"daumAutocompleteContainer": "DaumPlaceInput-module-daumAutocompleteContainer-r6M9H",
	"daumAutocompleteWidgetInput": "DaumPlaceInput-module-daumAutocompleteWidgetInput-fUdbn",
	"daumAutocompleteWidgetContainer": "DaumPlaceInput-module-daumAutocompleteWidgetContainer-OYK0d",
	"mobileWidgetCloseIcon": "DaumPlaceInput-module-mobileWidgetCloseIcon-ZneCP",
	"daumAutocompleteWidgetInactive": "DaumPlaceInput-module-daumAutocompleteWidgetInactive-cRY9a"
};
export default ___CSS_LOADER_EXPORT___;
