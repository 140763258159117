// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes ContentWrapper-module-flickerAnimation-LZv4y{0%{opacity:.7}50%{opacity:.3}100%{opacity:.7}}@keyframes ContentWrapper-module-fadeInAnimation-iTjB4{0%{opacity:0}100%{opacity:1}}@keyframes ContentWrapper-module-fadeInFromTop-\\+\\+Yb-{from{opacity:0;transform:translateY(-100%)}to{opacity:1;transform:translateY(0)}}@keyframes ContentWrapper-module-fadeOutToTop-qWTRH{from{opacity:1;transform:translateY(0)}to{opacity:0;transform:translateY(-100%)}}@keyframes ContentWrapper-module-fadeInFromLeftAnimation-l\\+UQ7{0%{opacity:0;transform:translateX(-10%)}100%{opacity:1;transform:translateX(0)}}@keyframes ContentWrapper-module-textClip-06l1v{to{background-size:100% 100%}}@keyframes ContentWrapper-module-scroll-oq0ng{0%{opacity:1;transform:translateY(-112px)}100%{transform:translateY(45px)}}@keyframes ContentWrapper-module-flickerAnimation-LZv4y{0%{opacity:.7}50%{opacity:.3}100%{opacity:.7}}@keyframes ContentWrapper-module-fadeInAnimation-iTjB4{0%{opacity:0}100%{opacity:1}}@keyframes ContentWrapper-module-fadeInFromTop-\\+\\+Yb-{from{opacity:0;transform:translateY(-100%)}to{opacity:1;transform:translateY(0)}}@keyframes ContentWrapper-module-fadeOutToTop-qWTRH{from{opacity:1;transform:translateY(0)}to{opacity:0;transform:translateY(-100%)}}@keyframes ContentWrapper-module-fadeInFromLeftAnimation-l\\+UQ7{0%{opacity:0;transform:translateX(-10%)}100%{opacity:1;transform:translateX(0)}}@keyframes ContentWrapper-module-textClip-06l1v{to{background-size:100% 100%}}@keyframes ContentWrapper-module-scroll-oq0ng{0%{opacity:1;transform:translateY(-112px)}100%{transform:translateY(45px)}}.ContentWrapper-module-contentWrapper-aiKun{border-radius:8px;text-align:center;color:#373a36}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentWrapper": "ContentWrapper-module-contentWrapper-aiKun",
	"flickerAnimation": "ContentWrapper-module-flickerAnimation-LZv4y",
	"fadeInAnimation": "ContentWrapper-module-fadeInAnimation-iTjB4",
	"fadeInFromTop": "ContentWrapper-module-fadeInFromTop-++Yb-",
	"fadeOutToTop": "ContentWrapper-module-fadeOutToTop-qWTRH",
	"fadeInFromLeftAnimation": "ContentWrapper-module-fadeInFromLeftAnimation-l+UQ7",
	"textClip": "ContentWrapper-module-textClip-06l1v",
	"scroll": "ContentWrapper-module-scroll-oq0ng"
};
export default ___CSS_LOADER_EXPORT___;
