// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Disclaimer-module-disclaimer-kn3OA p{font-weight:200;text-align:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"disclaimer": "Disclaimer-module-disclaimer-kn3OA"
};
export default ___CSS_LOADER_EXPORT___;
