// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../checkout/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Dialog-module-container-Tw8r2{position:fixed;top:0;inset-inline-start:0;width:100%;height:100%;overflow:auto;display:none;align-items:center;background-color:var(--background-color);-webkit-backdrop-filter:var(--backdrop-filter);backdrop-filter:var(--backdrop-filter);padding-inline:20px;z-index:100}.Dialog-module-container-Tw8r2.Dialog-module-isShown-HtvDX{display:flex}.Dialog-module-container-Tw8r2.Dialog-module-isContainerFullscreen-hhQYz{padding:0}.Dialog-module-dialog-NFcjr{position:relative;background-color:#fff;border-radius:8px;box-shadow:0 5px 15px rgba(0,0,0,.1);max-height:100%;max-width:100%;margin-inline:auto;overflow:auto;padding-block:48px 20px;padding-inline:20px}@media(min-width: 960px){.Dialog-module-dialog-NFcjr{max-width:800px;padding-block:48px 24px;padding-inline:24px}}.Dialog-module-dialogDark-ihFDm{background:#000}.Dialog-module-dialogFullscreen-JKhls{width:100%;height:100%}@media(min-width: 960px){.Dialog-module-dialogFullscreen-JKhls{max-width:1280px}}.Dialog-module-noPadding-Z8mrI{padding:0}.Dialog-module-close-rfw7N{position:absolute;inset-inline-end:30px;top:30px}.Dialog-module-dialogDark-ihFDm .Dialog-module-close-rfw7N{color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Dialog-module-container-Tw8r2",
	"isShown": "Dialog-module-isShown-HtvDX",
	"isContainerFullscreen": "Dialog-module-isContainerFullscreen-hhQYz",
	"dialog": "Dialog-module-dialog-NFcjr",
	"dialogDark": "Dialog-module-dialogDark-ihFDm",
	"dialogFullscreen": "Dialog-module-dialogFullscreen-JKhls",
	"noPadding": "Dialog-module-noPadding-Z8mrI",
	"close": "Dialog-module-close-rfw7N"
};
export default ___CSS_LOADER_EXPORT___;
